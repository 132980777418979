<template>
  <div class="exhibitor_list">
    <div class="ex" v-if="list && list.length">
      <div class="ex_item" @click="linkToExhibitorDetails(item.id)" v-for="(item, index) in list" :key="index">
        <div :class="['item_left',{'round': !exhibitorWebLogo}]">
          <div class="is_live" v-if="item.isLiving">
            <span></span>
            直播中
          </div>
          <img v-if="item.company.logo" :src="item.company.logo" v-load="'oPic'" :class="{'round': !exhibitorWebLogo }" />
          <img v-else :src="exhibitionInfo.logo" :class="{'round': !exhibitorWebLogo }" />
        </div>
        <div class="item_right">
          <div class="rig_top">
            <p class="ellipsis1">{{ item.company.name }}</p>
            <div class="continuous_ex" v-if="item.continuouNumber">
              <div class="img_icon">
                <img src="@/assets/img/jinpai.png" v-if="item.continuouNumber > 7" />
                <img src="@/assets/img/yinpai.png" v-else-if="item.continuouNumber < 5" />
                <img src="@/assets/img/tongpai.png" v-else />
              </div>
              <span>连续参展{{ item.continuouNumber }}年</span>
            </div>
          </div>
          <div class="rig_2">
            <div class="ellipsis1">
              <template v-if="item.boothInfo">
                <i class="iconfont icon-dizhi"></i>
                <span class="addr" @click.stop="Totour(item)">{{ item.boothInfo }}</span>
              </template>
              <template v-if="item.category">
                <i class="iconfont icon-biaoqian1"></i>
                <span>{{ item.category }}</span>
              </template>
            </div>
            <div class="rig_3">
              <p class="issue">
                共发布：<span>{{ item.productNumber }} </span>产品<span class="span2">{{ item.activityNumber }}
                </span>活动
              </p>
              <div class="like_count">
                <template v-for="(mode, index) in item.likes">
                  <img :src="JSON.parse(mode.userInfo).avatarUrl" v-if="JSON.parse(mode.userInfo).avatarUrl" v-load="'oPicXS'" :key="index" />
                  <img src="@/assets/img/user.png" v-else :key="index" />
                </template>
                <p>共{{ item.likeCount }}人点赞推荐</p>
              </div>
            </div>
            <p class="rig_4 ellipsis1" v-if="item.newestEvaluates[0]">
              最新评价：{{ item.newestEvaluates[0].content }}
            </p>
          </div>
        </div>
        <div class="hot_rank" v-if="item.isPopularize">
          <span>热榜</span>
        </div>
      </div>
    </div>
    <template v-if="!isList">
      <div class="no_more" v-if="loading">
        <p>加载中...</p>
      </div>
      <div class="no_more" v-else-if="finished">
        <p>没有更多了</p>
      </div>
      <div class="more" @click="moreClick" v-else-if=" list && list.length">
        <p>加载更多</p>
      </div>
      <div class="no_more" v-else-if="!list && !list.length">暂无数据！</div>
    </template>
    <template v-else>
      <div class="no_more" style="width:100%" v-if="finished && !list.length">暂无数据！</div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "exhibitorList",
  props: {
    finished: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  computed: {
    ...mapGetters({
         enabledBmm: "getEnabledBmm",
      exhibitionInfo: "getExhibitionInfo",
      exhibitionConfig: "getExhibitionConfig",
    }),
    // 判断展商Logo是方形还是圆形
    exhibitorWebLogo() {
      // 方形为true 圆形为false
      return this.exhibitionConfig && this.exhibitionConfig.display
        ? this.exhibitionConfig.display.exhibitorWebLogo === "SQUARE"
        : false;
    },
  },
  components: {},
  methods: {
    ...mapActions({
      linkToExhibitorDetails: "linkTo/linkToExhibitorDetails",
      linkToTour: "linkTo/linkToTour",
    }),
    setData(arr) {
      this.list = arr;
    },
    moreClick() {
      this.$emit("moreClick");
    },
    Totour(item) {
        if(!this.enabledBmm){
           this.$Message.info("暂未开启展位图!");
           return
        }
      this.linkToTour();
      this.$store.commit("setExhibitor", item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.exhibitor_list {
  width: 100%;
  .ex {
    display: flex;
    flex-wrap: wrap;
    .ex_item {
      width: 450px;
      height: 136px;
      margin: 0 20px 20px 0;
      background: #fff;
      border: solid 1px #ededed;
      padding: 20px;
      display: flex;
      position: relative;
      overflow: hidden;
      &:hover {
        @include border_color(#1890ff);
        cursor: pointer;
      }
      &:nth-child(even) {
        margin-right: 0;
      }
      .item_left {
        width: 96px;
        height: 96px;
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        border: 1px solid #e6e6e6;
        margin-right: 10px;
        img {
          display: block;
          height: 100%;
          width: 100%;
        }
        .is_live {
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 62px;
          height: 20px;
          background-image: linear-gradient(90deg, #ff416c 0%, #ff4b2b 100%),
            linear-gradient(#000, #000);
          color: #fff;
          font-size: 12px;
          padding: 4px 6px;
          span {
            display: block;
            width: 8px;
            height: 8px;
            background: #fff;
            border-radius: 50%;
          }
        }
      }
      .item_right {
        line-height: 1.3;
        max-width: calc(100% - 106px);
        overflow: hidden;
        .rig_top {
          display: flex;
          align-items: center;
          p {
            color: #333;
            font-weight: bold;
            font-size: 16px;
          }
          .continuous_ex {
            margin-left: 10px;
            flex-shrink: 0;
            height: 20px;
            background: rgba(#ff5e00, 0.15);
            display: flex;
            align-items: center;
            color: #ff5e00;
            font-size: 12px;
            padding: 1px;
            .img_icon {
              width: 18px;
              height: 18px;
              margin-right: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #fff;
              img {
                width: 14px;
                height: 14px;
              }
            }
            span {
              margin-right: 5px;
            }
          }
        }
        .rig_2 {
          margin-top: 6px;
          font-size: 14px;
          color: #333;
          .iconfont {
            font-size: 14px;
            margin-right: 7px;
            &.icon-dizhi {
              color: #fe781d;
            }
            &.icon-biaoqian1 {
              @include font_color(#1890ff);
            }
          }
          .addr {
            margin-right: 19px;
          }
        }
        .rig_3 {
          margin-top: 6px;
          display: flex;
          align-items: center;
          .issue {
            font-size: 12px;
            color: #333;
            span {
              color: #ff5c00;
              &.span2 {
                margin-left: 8px;
              }
            }
          }
          .like_count {
            margin-left: 20px;
            display: flex;
            align-items: center;
            img {
              width: 21px;
              height: 21px;
              border-radius: 50%;
              border: 1px solid #fff;
              z-index: 3;
              position: relative;
              &:nth-child(2) {
                z-index: 2;
                margin-left: -10px;
              }
              &:nth-child(3) {
                z-index: 1;
                margin-left: -10px;
              }
            }
            p {
              color: #666;
              font-size: 12px;
              margin-left: 6px;
            }
          }
        }
        .rig_4 {
          margin-top: 8px;
          color: #666;
          font-size: 12px;
        }
      }
      .hot_rank {
        width: 60px;
        height: 60px;
        background: rgba(#ff5e00, 0.15);
        position: absolute;
        right: -30px;
        top: -30px;
        transform: rotate(45deg);
        span {
          position: absolute;
          bottom: 4px;
          left: 50%;
          font-size: 12px;
          color: #ff5e00;
          transform: translateX(-50%);
          line-height: 1;
          white-space: nowrap;
        }
      }
    }
  }
}

.round {
  border-radius: 50% !important;
}
</style>
